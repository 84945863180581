//helpers
const qs = (selector) => document.querySelector(selector);
const qsa = (selector) => document.querySelectorAll(selector);

import "./import/modules";
import IMask from 'imask';
import emailautocomplete from 'js-email-autocomplete';
document.addEventListener("DOMContentLoaded", () => {

    const langSwitcher = qs(".lang-switcher");
    const langSwitcherItems = qsa(".lang-switcher__item");

    const body = qs("body");


    langSwitcherItems.forEach(e => {
        e.onclick = function () {
            langSwitcherItems.forEach(e => e.classList.remove("lang-switcher_active"));
            this.classList.add("lang-switcher_active");
            if (langSwitcher.classList.contains("lang-switcher_ru")) {
                langSwitcher.classList.remove("lang-switcher_ru");
                langSwitcher.classList.add("lang-switcher_en");
            } else {
                langSwitcher.classList.remove("lang-switcher_en");
                langSwitcher.classList.add("lang-switcher_ru");
            }
        }
    });

    const phoneMask = IMask(
        document.querySelector('input[name="phone"]'), {
        mask: '+{375}(00)000-00-00'
    });


    const email = document.querySelector('input[name="email"]');
    emailautocomplete(email, {
        domains: ["mail.com"], //add your own specific domains to top of default list
        suggClass: 'eac-suggestion' //add your own class
    });

    const menuArr = qsa("li.menu__item");
    console.log(menuArr)
    menuArr.forEach(e => {
        e.onclick = function () {
            console.log(3)
            this.classList.toggle("active");
        }
    })
    if (body.classList.contains("catalog-page")) {
      const catalogArr = qsa(".sidebar .catalog__nav li.nav__link.head");
      console.log(catalogArr);
      catalogArr.forEach(e => {
        e.onclick = function() {
          console.log(3)
          this.classList.toggle("active");
        }
      })
    }
    


    var Menu = {
        el: {
          ham: qs(".hamburger"),
          menuTop: qs(".menu-top"),
          menuMiddle: qs(".menu-middle"),
          menuBottom: qs(".menu-bottom")
        },
      
        init: function() {
          Menu.bindUIactions();
          console.log(3)
        },
      
        bindUIactions: function() {
          Menu.el.ham.onclick=  function(event) {
            Menu.activateMenu(event);
            event.preventDefault();
          };
        },
      
        activateMenu: function() {
          Menu.el.menuTop.classList.toggle("menu-top-click");
          Menu.el.menuMiddle.classList.toggle("menu-middle-click");
          Menu.el.menuBottom.classList.toggle("menu-bottom-click");
          body.classList.toggle("show-menu");
        }
      };
      
      Menu.init();


   
      

})
