import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider";
if(document.querySelector("body").classList.contains("main")) {
  const slider = tns({
    container: '.slider',
    items: 1,
    slideBy: 'page',
    aytoplay: false,
    arrowKeys: true,
    nav: false,
    controlsText: ["",""],
    pagination: false,
    
    swipeAngle: false,
  });
}
